.premier-sponsor-package {
    background-color: #1e1e1e;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    text-align: center;
    max-width: 800px;
    margin: 0 auto 60px;
    color: #b3b3b3;
}

.premier-sponsor-package:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.6);
}

.premier-sponsor-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #e50914;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.premier-sponsor-description {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 30px;
}

.premier-sponsor-description .highlight {
    color: #e50914;
    font-weight: bold;
}

.premier-sponsor-table {
    margin: 30px 0;
}

.premier-sponsor-table table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    text-align: left;
}

.premier-sponsor-table th,
.premier-sponsor-table td {
    padding: 12px 15px;
    border: 1px solid #444;
    font-size: 1.1rem;
}

.premier-sponsor-table th {
    background-color: #e50914;
    color: white;
    text-transform: uppercase;
}

.premier-sponsor-table td {
    background-color: #2a2a2a;
    color: #b3b3b3;
}

.premier-sponsor-highlights, .premier-sponsor-stats, .premier-sponsor-testimonials {
    margin-bottom: 30px;
    text-align: left;
}

.premier-sponsor-stats h3, .premier-sponsor-testimonials h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #e50914;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.premier-sponsor-stats ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.premier-sponsor-stats li {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 10px;
}

.premier-sponsor-testimonials blockquote {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #b3b3b3;
    background-color: #141414;
    padding: 20px;
    border-left: 5px solid #e50914;
    margin: 0;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.premier-sponsor-cta-button {
    display: block;
    width: 80%;
    margin: 20px auto 0;
    padding: 12px 24px;
    background-color: #e50914;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.premier-sponsor-cta-button:hover {
    background-color: #ff5733;
    transform: translateY(-2px);
}
