/* src/components/styles/ImpactStats.css */

.impact-stats {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    color: white;
    padding: 20px 0;
    gap: 20px;
}

.impact-stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #E50914; /* Netflix Red */
    border-radius: 5px;
    transition: transform 0.3s ease-in-out;
}

.impact-number {
    font-size: 2rem;
    font-weight: bold;
}

.impact-label {
    font-size: 1rem;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .impact-stats {
        flex-direction: column;
        gap: 10px;
    }

    .impact-stat {
        width: 100%;
    }
}
