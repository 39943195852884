/* src/pages/styles/UpcomingEvents.css */

/* General Styles */
.upcoming-events-page {
  padding: 80px 20px;
  margin-top: 120px; /* Generous margin-top for spacing from navigation */
  max-width: 1200px;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #ffffff;
  background-color: #141414; /* Netflix's signature dark background */
  animation: fadeIn 1s ease-in-out; /* Subtle fade-in animation */
}

h1 {
  font-size: 3rem;
  font-weight: 700;
  margin: 0 auto 40px auto;
  text-align: center;
  color: #e50914; /* Netflix Red for prominent elements */
  padding-top: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: slideInFromBottom 0.8s ease-out; /* Slide in from bottom */
}

/* Event List Styles */
.events-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  padding-bottom: 80px;
}

.event {
  background-color: #1f1f1f; /* Slightly lighter dark background */
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  transform: translateY(0);
  animation: fadeInUp 0.5s ease-in-out;
}

.event:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  transform: translateY(-10px);
}

.event-name {
  font-size: 2rem;
  font-weight: 600;
  margin: 20px;
  color: #e50914;
  transition: color 0.3s ease;
}

.event:hover .event-name {
  color: #ff4c4c; /* Slightly brighter red on hover */
}

.event-date {
  font-size: 1.2rem;
  color: #b3b3b3; /* Light gray */
  margin: 0 20px 10px 20px;
}

.event-summary {
  font-size: 1.3rem;
  line-height: 1.8;
  margin: 0 20px 20px 20px;
  color: #d1d1d1; /* Light gray for summary text */
  transition: color 0.3s ease;
}

.event:hover .event-summary {
  color: #ffffff; /* White on hover */
}

.event-details, .event-venues {
  padding: 0 20px 20px 20px;
}

.event-details h3, .event-venues h3 {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #e50914; /* Netflix Red for headings */
  text-transform: uppercase;
}

.event-details ul, .event-venues ul {
  list-style-type: none;
  padding-left: 0;
}

.event-details li, .event-venues li {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #d1d1d1; /* Light gray */
  position: relative;
  padding-left: 25px;
}

.event-details li::before, .event-venues li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #e50914; /* Netflix Red */
  font-size: 1.6rem;
  line-height: 1rem;
  transition: transform 0.3s ease;
}

.event:hover .event-details li::before, .event:hover .event-venues li::before {
  transform: translateX(5px);
}

/* No Events Message */
.no-events-message {
  font-size: 1.5rem;
  text-align: center;
  color: #b3b3b3;
  margin-top: 60px;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .events-list {
    grid-template-columns: 1fr;
  }

  h1 {
    font-size: 2.5rem;
  }

  .event-name {
    font-size: 1.8rem;
  }

  .event-summary {
    font-size: 1.2rem;
  }

  .event-details h3, .event-venues h3 {
    font-size: 1.3rem;
  }

  .event-details li, .event-venues li {
    font-size: 1.1rem;
  }
}
