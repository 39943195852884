/* src/components/styles/Footer.css */

/* General Styles */
.footer-container {
    background-color: #000000;
    color: #ffffff;
    text-align: center;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease-in-out;
    animation: fadeInUp 0.5s ease-out; /* Smooth fade-in animation */
}

.footer-container:hover {
    background-color: #141414; /* Slightly lighter background on hover */
}

/* Grid Layout for Footer Sections */
.footer-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 40px;
    width: 100%;
    max-width: 1200px;
    align-items: start;
    text-align: left;
}

/* Footer Section Styles */
.footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-section h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: #e50914; /* Netflix Red */
    text-transform: uppercase;
}

.footer-section p {
    margin: 0;
    font-size: 1rem;
    color: #b3b3b3;
}

/* Social Links Styles */
.social-links {
    display: flex;
    gap: 20px;
}

.social-icon {
    color: #ffffff;
    font-size: 1.8rem;
    transition: color 0.3s ease, transform 0.3s ease;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.social-icon:hover {
    color: #e50914;
    transform: scale(1.1);
}

.social-icon:focus {
    outline: none;
    transform: scale(1.1);
}

/* Contact Info Styles */
.contact-info {
    margin-top: 15px;
    font-size: 1rem;
    color: #b3b3b3;
    text-transform: uppercase;
}

.contact-info p {
    margin: 0;
    display: inline; /* Keep inline to ensure email is on the same line */
}

.email-link {
    color: #e50914; /* Netflix Red for email link */
    text-decoration: none;
    margin-left: 5px; /* Space between "Email:" and the address */
    transition: color 0.3s ease;
    display: inline; /* Keep inline to stay on the same line */
}

.email-link:hover {
    color: #ff4c4c; /* Lighter red on hover */
}

/* Quick Links Styles */
.quick-links {
    list-style: none;
    padding: 0;
    margin: 0;
}

.quick-links li {
    margin: 5px 0;
}

.quick-links a {
    color: #b3b3b3;
    text-decoration: none;
    transition: color 0.3s ease;
}

.quick-links a:hover {
    color: #e50914;
}

/* Motion Design Enhancements */
.footer-container {
    position: relative;
    overflow: hidden;
}

.footer-container::before {
    content: '';
    position: absolute;
    top: -150%;
    left: -150%;
    width: 400%;
    height: 400%;
    background: radial-gradient(circle, rgba(229,9,20,0.2), rgba(0,0,0,0));
    transition: top 0.8s ease-in-out, left 0.8s ease-in-out;
    z-index: -1;
    animation: moveGradient 15s infinite linear;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes moveGradient {
    0% {
        top: -150%;
        left: -150%;
    }
    50% {
        top: 0%;
        left: 0%;
    }
    100% {
        top: -150%;
        left: -150%;
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .footer-grid {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .footer-section h3 {
        font-size: 1.2rem;
    }

    .social-icon {
        font-size: 1.5rem;
    }

    .contact-info {
        font-size: 0.9rem;
    }
}
