.sponsor-page-info-section {
    padding: 100px 20px 60px;
    background: linear-gradient(135deg, #141414, #1f1f1f);
    color: white;
    text-align: center;
    font-family: 'Helvetica Neue', Arial, sans-serif;
}

.sponsor-page-info-header {
    margin-bottom: 40px;
}

.sponsor-page-info-header h2 {
    font-size: 3rem;
    color: #e50914;
    margin-bottom: 20px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.sponsor-page-info-header p {
    font-size: 1.2rem;
    color: #b3b3b3;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.5;
}

.sponsor-page-info-target-audience, .sponsor-page-info-social-media-reach, .sponsor-page-info-audience-demographics {
    margin: 60px 0;
}

.sponsor-page-info-pie-chart-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    background-color: #1e1e1e;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.sponsor-page-info-pie-chart-container:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
}

.sponsor-page-info-pie-chart-container h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-align: center;
}

.sponsor-page-info-pie-chart-container p {
    font-size: 1.1rem;
    color: #b3b3b3;
    margin-bottom: 20px;
}

.sponsor-page-info-packages, .sponsor-page-info-deals {
    margin: 60px 0;
}

.sponsor-page-info-packages h3, .sponsor-page-info-deals h3 {
    font-size: 2.5rem;
    color: #e50914;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.sponsor-page-info-packages p.sponsor-page-info-description-tournament, .sponsor-page-info-deals p.sponsor-page-info-description-yearlong {
    font-size: 1.1rem;
    color: #b3b3b3;
    max-width: 800px;
    margin: 0 auto 30px;
    line-height: 1.5;
    background-color: #1e1e1e;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.sponsor-page-info-description-tournament .highlight, .sponsor-page-info-description-yearlong .highlight {
    color: #e50914;
    font-weight: bold;
}

.sponsor-page-info-packages ul, .sponsor-page-info-deals ul {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.sponsor-page-info-packages li, .sponsor-page-info-deals li {
    background: #1e1e1e;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
}

.sponsor-page-info-packages li:hover, .sponsor-page-info-deals li:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.6);
}

.sponsor-page-info-packages h4, .sponsor-page-info-deals h4 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.sponsor-page-info-packages p, .sponsor-page-info-deals p {
    font-size: 1.1rem;
    color: #b3b3b3;
    line-height: 1.6;
}

.sponsor-page-info-cta-button {
    display: block;
    width: 80%;
    margin: 20px auto 0;
    padding: 12px 24px;
    background-color: #e50914;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.sponsor-page-info-cta-button:hover {
    background-color: #ff5733;
    transform: translateY(-2px);
}

.sponsor-page-info-testimonials {
    margin: 60px 0;
}

.sponsor-page-info-testimonials h3 {
    font-size: 2.5rem;
    color: #e50914;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.sponsor-page-info-testimonials p {
    font-size: 1.2rem;
    color: #b3b3b3;
    max-width: 800px;
    margin: 0 auto;
    background-color: #1e1e1e;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    line-height: 1.6;
}

.sponsor-page-info-benefits {
    margin: 60px 0;
}

.sponsor-page-info-benefits h3 {
    font-size: 2.5rem;
    color: #e50914;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.sponsor-page-info-benefits p {
    font-size: 1.2rem;
    color: #b3b3b3;
    max-width: 800px;
    margin: 0 auto;
    background-color: #1e1e1e;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    line-height: 1.6;
}

.sponsor-page-info-contact {
    margin: 60px 0;
}

.sponsor-page-info-contact h3 {
    font-size: 2.5rem;
    color: #e50914;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.sponsor-page-info-contact-form {
    max-width: 800px;
    margin: 0 auto;
    background-color: #1e1e1e;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.sponsor-page-info-contact-form input,
.sponsor-page-info-contact-form textarea {
    width: 100%;
    padding: 12px;
    margin: 15px 0;
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    background-color: #141414;
    color: white;
    font-size: 1rem;
}

.sponsor-page-info-contact-button {
    width: 100%;
    padding: 12px;
    background-color: #e50914;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.sponsor-page-info-contact-button:hover {
    background-color: #ff5733;
    transform: translateY(-2px);
}
