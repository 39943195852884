/* styles/Header.css */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 70px;
    background-color: #000;
    color: #fff;
    border-bottom: 2px solid #e50914; /* Netflix Red */
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.logo-container {
    flex: 1;
}

.logo {
    height: 50px;
    cursor: pointer;
}

.nav {
    flex: 3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nav-list {
    display: flex;
    list-style: none;
    transition: transform 0.3s ease-in-out;
}

.nav-list.open {
    transform: translateX(0);
}

.nav-item {
    margin: 0 10px;
    position: relative;
}

.nav-item a {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    transition: color 0.2s;
}

.nav-item a:hover {
    color: #e50914; /* Netflix Red */
}

.cta-container {
    margin-left: 20px;
}

.cta-button {
    padding: 10px 20px;
    background-color: #e50914; /* Netflix Red */
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    border-radius: 3px;
    transition: background-color 0.2s;
}

.cta-button:hover {
    background-color: #f40612;
}

.hamburger-icon {
    display: none;
    cursor: pointer;
}

.hamburger-icon svg {
    color: #fff;
    font-size: 24px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .nav {
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        height: calc(100vh - 70px);
        background-color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
    }

    .nav.open {
        transform: translateX(0);
    }

    .nav-list {
        flex-direction: column;
    }

    .nav-item {
        margin: 20px 0;
    }

    .cta-container {
        margin-top: 20px;
    }

    .hamburger-icon {
        display: block;
    }
}
