/* styles/Hero.css */

.hero-section {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
    text-align: center;
    color: white;
    overflow: hidden;
    width: 100vw; /* Full width of the viewport */
}

.hero-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    z-index: 0;
    pointer-events: none; /* Ensures overlay doesn't capture pointer events */
}

.content {
    position: relative;
    z-index: 1;
}

.hero-title {
    font-size: 4em;
    margin: 0.5em 0;
}

@media (max-width: 1200px) {
    .hero-title {
        font-size: 3em;
    }
}

@media (max-width: 768px) {
    .hero-title {
        font-size: 2.5em;
    }
}

@media (max-width: 480px) {
    .hero-title {
        font-size: 2em;
    }
}

.hero-subtitle {
    font-size: 2em;
    margin: 0.5em 0;
}

@media (max-width: 1200px) {
    .hero-subtitle {
        font-size: 1.8em;
    }
}

@media (max-width: 768px) {
    .hero-subtitle {
        font-size: 1.5em;
    }
}

@media (max-width: 480px) {
    .hero-subtitle {
        font-size: 1.2em;
    }
}

.hero-button {
    background-color: #E50914;
    color: white;
    padding: 10px 20px;
    font-size: 1.2em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.hero-button:hover {
    background-color: #C40812;
}

@media (max-width: 1200px) {
    .hero-button {
        font-size: 1.1em;
        padding: 12px 24px;
    }
}

@media (max-width: 768px) {
    .hero-button {
        font-size: 1em;
        padding: 10px 20px;
    }
}

@media (max-width: 480px) {
    .hero-button {
        font-size: 0.9em;
        padding: 8px 16px;
    }
}
