/* General Styles */
.event {
    position: relative;
    padding: 25px;
    margin: 25px 0;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    background-color: #1f1f1f;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
  }
  
  /* Decorative Red Line */
  .event::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: #e50914; /* Netflix Red */
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.3s ease;
  }
  
  /* Hover Effects */
  .event:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  }
  
  .event:hover::before {
    transform: scaleY(1);
  }
  
  .event:hover .event-name {
    color: #ff4c4c; /* Lighter shade of red on hover */
  }
  
  .event:hover .event-summary {
    color: #ffffff; /* White on hover */
  }
  
  /* Event Name */
  .event-name {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 12px;
    color: #e50914; /* Netflix Red */
    transition: color 0.3s ease;
  }
  
  /* Event Date */
  .event-date {
    font-size: 1.1rem;
    color: #b3b3b3; /* Light gray */
    margin-bottom: 15px;
  }
  
  /* Event Summary */
  .event-summary {
    font-size: 1.2rem;
    line-height: 1.6;
    margin: 15px 0;
    color: #d1d1d1; /* Light gray */
    transition: color 0.3s ease;
  }
  
  /* Event Details and Venues */
  .event-details, .event-venues {
    margin-top: 25px;
  }
  
  .event-details h3, .event-venues h3 {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 12px;
    color: #e50914; /* Netflix Red */
    transition: color 0.3s ease;
  }
  
  /* Lists in Details and Venues */
  .event-details ul, .event-venues ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .event-details li, .event-venues li {
    margin-bottom: 10px;
    font-size: 1.1rem;
    color: #d1d1d1; /* Light gray */
    padding-left: 20px;
    position: relative;
  }
  
  /* List Item Bullets */
  .event-details li::before, .event-venues li::before {
    content: '•';
    position: absolute;
    left: 0;
    color: #e50914; /* Netflix Red */
    font-size: 1.4rem;
    line-height: 1rem;
    transition: transform 0.3s ease;
  }
  
  /* Motion Design Enhancements */
  .event:hover .event-details li::before, .event:hover .event-venues li::before {
    transform: translateX(5px);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .event {
      padding: 20px;
      margin: 20px 0;
    }
  
    .event-name {
      font-size: 1.5rem;
    }
  
    .event-date {
      font-size: 1rem;
    }
  
    .event-summary {
      font-size: 1.1rem;
    }
  
    .event-details h3, .event-venues h3 {
      font-size: 1.2rem;
    }
  
    .event-details li, .event-venues li {
      font-size: 1rem;
    }
  }
  