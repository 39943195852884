/* src/components/styles/About.css */

.about-section {
    padding: 60px 20px;
    background-color: #141414;
    color: white;
    text-align: center;
  }
  
  .about-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: center;
  }
  
  .about-title {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #e50914;
  }
  
  .about-subtitle {
    font-size: 2em;
    margin-top: 40px;
    color: #e50914;
  }
  
  .about-paragraph {
    font-size: 1.2em;
    line-height: 1.6;
    margin: 20px 0;
    color: #ffffff;
  }
  
  .about-list {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .about-list-item {
    font-size: 1.2em;
    margin: 10px 0;
    color: #ffffff;
  }
  
  .about-highlight {
    color: #e50914;
    font-weight: bold;
  }
  
  .about-leadership-section {
    padding: 60px 20px;
    background-color: #1f1f1f;
    color: white;
    text-align: center;
  }
  
  .about-leader-title {
    font-size: 2.5em;
    margin-bottom: 40px;
    color: #e50914;
  }
  
  .about-leader-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .about-leader {
    flex: 1 1 300px;
    margin: 20px;
    text-align: center;
  }
  
  .about-leader-image {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .about-leader-image:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.7);
  }
  
  .about-leader-name {
    margin: 10px 0;
    font-size: 1.5em;
    color: #ffffff;
  }
  
  .about-leader-role {
    font-size: 1em;
    color: #888;
  }
  
  /* Video styling */
  .about-video {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 500px; /* Constrain the height */
    border: 2px solid #e50914; /* Netflix Red border */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Subtle shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .about-video:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.7);
  }
  