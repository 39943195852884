.growth-metrics-section {
    padding: 100px 20px 40px;
    text-align: center;
    background-color: #141414; /* Dark background similar to Netflix */
    color: white;
    overflow-x: hidden;
    animation: fadeIn 1.5s ease-in-out;
}

.growth-metrics-section h2 {
    font-size: 3em;
    margin-bottom: 20px;
    color: #e50914; /* Netflix Red */
    text-transform: uppercase;
    letter-spacing: 2px;
    animation: slideInFromLeft 1s ease-out;
    background: linear-gradient(90deg, #e50914, #ff5733);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.growth-metrics-section p {
    font-size: 1.3em;
    margin-bottom: 40px;
    color: #b3b3b3; /* Lighter shade for better contrast */
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    animation: slideInFromRight 1s ease-out;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    padding: 20px;
    border-radius: 10px;
}

.growth-metrics-section .highlight {
    color: #e50914; /* Netflix Red for emphasis */
    font-weight: bold;
}

.chart-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto 40px;
    background-color: #1e1e1e; /* Darker background for chart container */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    animation: fadeInUp 1s ease-in-out;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.chart-container h3 {
    font-size: 1.8em;
    margin-bottom: 20px;
    color: #e50914; /* Netflix Red */
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-align: center;
}

.chart-container p.chart-description {
    font-size: 1.1em;
    margin-bottom: 20px;
    color: #b3b3b3;
    text-align: center;
}

.chart-container:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes slideInFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideInFromRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Design */
@media (max-width: 1200px) {
    .growth-metrics-section h2 {
        font-size: 2.5em;
    }

    .growth-metrics-section p {
        font-size: 1.1em;
    }

    .chart-container h3 {
        font-size: 1.5em;
    }
}

@media (max-width: 768px) {
    .growth-metrics-section h2 {
        font-size: 2.2em;
    }

    .growth-metrics-section p {
        font-size: 1em;
    }

    .chart-container h3 {
        font-size: 1.3em;
    }
}

@media (max-width: 480px) {
    .growth-metrics-section {
        padding: 80px 10px 20px;
    }

    .growth-metrics-section h2 {
        font-size: 2em;
    }

    .growth-metrics-section p {
        font-size: 0.9em;
        padding: 15px;
    }

    .chart-container h3 {
        font-size: 1.1em;
    }

    .chart-container {
        padding: 10px;
    }
}
