.sponsor-page-data-visualization {
    background-color: #1e1e1e;
    color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin: 0 auto; /* Center the component */
    max-width: 800px; /* Limit the width for better centering */
  }
  
  .sponsor-page-data-visualization h2 {
    font-size: 2.5rem;
    color: #e50914;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  
  .sponsor-page-chart-container {
    margin: 30px 0;
  }
  
  .sponsor-page-chart-container h3 {
    font-size: 2rem;
    color: #e50914;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  
  .recharts-legend-item text {
    color: white;
  }
  