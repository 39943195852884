/* styles/ImpactSection.css */

.impact-section {
    padding: 40px 20px;
    text-align: center;
    background-color: #141414; /* Dark background similar to Netflix */
    color: white;
    overflow-x: hidden;
}

.impact-section h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.impact-section p {
    font-size: 1.2em;
    margin-bottom: 40px;
    color: #b3b3b3; /* Lighter shade for better contrast */
}

.chart-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}

.impact-section button {
    background-color: #E50914;
    color: white;
    padding: 10px 20px;
    font-size: 1.2em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
}

.impact-section button:hover {
    background-color: #C40812;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .impact-section h2 {
        font-size: 2.2em;
    }

    .impact-section p {
        font-size: 1.1em;
    }
}

@media (max-width: 768px) {
    .impact-section h2 {
        font-size: 2em;
    }

    .impact-section p {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .impact-section h2 {
        font-size: 1.8em;
    }

    .impact-section p {
        font-size: 0.9em;
    }

    .chart-container {
        padding: 0 10px;
    }

    .impact-section button {
        font-size: 1em;
        padding: 8px 16px;
        margin-top: 100px; /* Add extra margin on mobile to prevent overlay */
    }
}
