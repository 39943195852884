/* src/components/styles/EventsCalendar.css */

/* General Page Styles */
.events-calendar-page {
    padding: 100px 20px;
    max-width: 900px;
    margin: 0 auto; /* Center alignment */
    color: #ffffff;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  }
  
  .description {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .description h1 {
    font-size: 3rem;
    color: #e50914; /* Netflix Red */
    margin-bottom: 20px;
  }
  
  .description p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #b3b3b3; /* Light gray */
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* Events Calendar Styles */
  .events-calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto; /* Center alignment */
    max-width: 900px; /* Center and limit the width */
    background-color: #141414; /* Netflix's signature dark background */
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4); /* Subtle shadow for depth */
    padding: 30px; /* Increased padding for spacing */
    animation: fadeInUp 1s ease-out; /* Smooth fade-in animation */
  }
  
  .events-calendar .react-calendar {
    width: 100%;
    border: none;
    background-color: transparent;
    color: #ffffff;
    font-family: inherit;
  }
  
  .events-calendar .react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 0 10px; /* Add padding to create space between buttons and edges */
  }
  
  .events-calendar .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    border: none;
    color: #ffffff;
    font-size: 1.2rem;
    margin: 0 5px; /* Add margin between navigation buttons */
    padding: 5px 10px;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .events-calendar .react-calendar__navigation button:hover {
    color: #e50914; /* Netflix Red on hover */
  }
  
  .events-calendar .react-calendar__tile {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 6px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .events-calendar .react-calendar__tile:hover {
    background-color: #333;
    transform: scale(1.05);
  }
  
  .events-calendar .react-calendar__tile--now {
    background: #444;
    border-radius: 6px;
    font-weight: bold;
  }
  
  .events-calendar .react-calendar__tile--active {
    background: #e50914;
    color: white;
    border-radius: 6px;
  }
  
  /* Event Dot Styles */
  .event-dot {
    background-color: #e50914; /* Netflix Red */
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin: 2px auto; /* Center the dot */
    transition: transform 0.2s ease-in-out;
  }
  
  .event-dot:hover {
    transform: scale(1.2); /* Slight scale on hover */
  }
  
  /* Event Details Styles */
  .event-details {
    margin-top: 30px;
    width: 100%;
    color: #ffffff;
    animation: fadeIn 0.5s ease-in-out; /* Smooth fade-in animation */
  }
  
  .event-details h2 {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 15px;
    color: #e50914; /* Netflix Red */
    text-transform: uppercase;
    letter-spacing: 1.5px;
    border-bottom: 2px solid #e50914; /* Red underline */
    padding-bottom: 5px;
    animation: slideInFromBottom 0.6s ease-out; /* Smooth slide-in animation */
  }
  
  .event-details h3 {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 10px 0;
    color: #ffffff;
  }
  
  .event-details p {
    font-size: 1.3rem;
    line-height: 1.6;
    color: #b3b3b3; /* Light gray */
    margin-bottom: 10px;
    animation: fadeIn 1s ease-in-out; /* Smooth fade-in animation */
  }
  
  .event-item {
    padding: 10px 0;
    border-bottom: 1px solid #333; /* Subtle separator for events */
  }
  
  .event-item:last-child {
    border-bottom: none; /* Remove border for last item */
  }
  
  /* Animations */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideInFromBottom {
    from {
      transform: translateY(30px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .events-calendar {
      padding: 20px 15px;
    }
  
    .event-details h2 {
      font-size: 2rem;
    }
  
    .event-details h3 {
      font-size: 1.5rem;
    }
  
    .event-details p {
      font-size: 1.1rem;
    }
  }
  