/* styles/PlayerShowcase.css */

.player-showcase-section {
    padding: 60px 20px;
    background: linear-gradient(135deg, #141414, #1f1f1f); /* Dark gradient background */
    color: white;
    text-align: center;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.player-showcase-section h2 {
    font-size: 2.5em;
    margin-bottom: 40px;
    background: linear-gradient(90deg, #e50914, #d4145a);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
}

.slick-slider {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    overflow: hidden; /* Ensure no horizontal scroll */
}

.slick-list {
    overflow: visible;
}

.slick-slide {
    transition: transform 0.3s ease, opacity 0.3s ease;
    padding: 10px; /* Padding between slides */
}

.slick-slide img {
    width: 100%;
    height: 300px;
    object-fit: cover; /* Prevents stretching */
    border-radius: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.slick-slide:not(.slick-center) {
    transform: scale(0.85); /* Scale down non-center slides */
    opacity: 0.6; /* Dim non-center slides */
}

.slick-center .slick-slide {
    transform: scale(1.1); /* Scale the center slide */
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.7); /* Elevated shadow for center slide */
    opacity: 1; /* Full opacity for center slide */
}

.slick-prev,
.slick-next {
    display: none; /* Ensure default arrows are not displayed */
}

.slick-dots {
    bottom: -30px;
}

.slick-dots li button:before {
    font-size: 12px;
    color: #e50914; /* Netflix Red */
    transition: transform 0.3s ease;
}

.slick-dots li.slick-active button:before {
    transform: scale(1.5);
    color: #ffffff;
}

.image-container {
    position: relative;
    padding: 10px;
}

.image-box {
    position: relative;
    background-color: #1e1e1e;
    border-radius: 15px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-box:hover {
    transform: scale(1.05);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.7);
}

.showcase-overlay {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    font-size: 1.2em;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    padding: 5px 10px;
    border-radius: 5px;
}

@media (max-width: 1200px) {
    .player-showcase-section h2 {
        font-size: 2.2em;
    }

    .slick-prev {
        left: 8px; /* Adjust left arrow position */
    }

    .slick-next {
        right: 8px; /* Adjust right arrow position */
    }
}

@media (max-width: 768px) {
    .player-showcase-section h2 {
        font-size: 2em;
    }

    .slick-prev {
        left: 5px; /* Adjust left arrow position */
    }

    .slick-next {
        right: 5px; /* Adjust right arrow position */
    }
}

@media (max-width: 480px) {
    .player-showcase-section h2 {
        font-size: 1.8em;
    }

    .slick-prev {
        left: 2px; /* Adjust left arrow position */
    }

    .slick-next {
        right: 2px; /* Adjust right arrow position */
    }
}
