/* styles/PlayersDrafted.css */

.players-drafted-section {
    padding: 80px 20px; /* Ensure there's enough padding to not be cut off by the nav */
    text-align: center;
    background: linear-gradient(135deg, #141414, #1f1f1f); /* Gradient background */
    color: white;
    overflow-x: hidden;
}

.players-drafted-section h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    background: linear-gradient(90deg, #e50914, #d4145a);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
}

.players-drafted-section p {
    font-size: 1.2em;
    margin-bottom: 40px;
    color: #b3b3b3; /* Lighter shade for better contrast */
}

.player-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    padding: 0;
    list-style: none;
    max-width: 1200px;
    margin: 0 auto;
}

.player-item {
    background: linear-gradient(145deg, #1e1e1e, #2a2a2a); /* Gradient background for cards */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    color: white;
}

.player-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.6);
}

.player-name {
    font-size: 1.6em;
    margin-bottom: 10px;
    color: #e50914; /* Netflix Red */
    font-weight: bold;
}

.player-university, .player-drafted {
    font-size: 1.1em;
    color: #b3b3b3;
    margin: 5px 0;
}

@media (max-width: 1200px) {
    .players-drafted-section h2 {
        font-size: 2.2em;
    }

    .players-drafted-section p {
        font-size: 1.1em;
    }

    .player-name {
        font-size: 1.4em;
    }

    .player-university, .player-drafted {
        font-size: 1em;
    }
}

@media (max-width: 768px) {
    .players-drafted-section h2 {
        font-size: 2em;
    }

    .players-drafted-section p {
        font-size: 1em;
    }

    .player-name {
        font-size: 1.2em;
    }

    .player-university, .player-drafted {
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {
    .players-drafted-section h2 {
        font-size: 1.8em;
    }

    .players-drafted-section p {
        font-size: 0.9em;
    }

    .player-name {
        font-size: 1.1em;
    }

    .player-university, .player-drafted {
        font-size: 0.8em;
    }
}
